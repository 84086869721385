import { cn } from '@/core/ui/utils';
import { FCC } from '@/types/common';
import { CSSProperties } from 'react';

type SkeletonProps = {
  visible?: boolean;
  radius?: string;
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
};

export const Skeleton: FCC<SkeletonProps> = ({
  children,
  visible = true,
  radius = 'rounded-2xl',
  height = 'auto',
  width = 'auto',
}) => (
  <div
    style={{
      width,
      height,
    }}
    className={cn(
      visible
        ? ['overflow-hidden', 'relative', 'h-auto', 'w-auto', radius]
        : ['before:hidden', 'after:hidden'],

      'before:absolute',
      'before:bottom-0',
      'before:top-0',
      'before:left-0',
      'before:right-0',
      'before:bg-gray-200',

      'after:absolute',
      'after:bottom-0',
      'after:top-0',
      'after:left-0',
      'after:right-0',
      'after:bg-gray-200',
      'after:animate-shine',
      'after:bg-[length:200%_100%]',
      'after:z-10',
      'after:bg-skeleton'
    )}
  >
    {children}
  </div>
);
